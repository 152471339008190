import authModule from '@/store/auth'
import { Component, Vue } from 'vue-property-decorator'
import BreadCrumb from '@/components/BreadCrumbs/BreadCrumbs.vue'
@Component({
  components: {
    BreadCrumb,
  },
})
export default class Navbar extends Vue {
  userName = ''
  nav_layout = ''
  open = false
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.userName = authModule.Name
  }
  showSide() {
    this.$emit('showSide')
  }
  navLayout(value: boolean) {
    if (value) {
      this.nav_layout = 'navbar-layout'
    }
  }
  navOpenSidebar(value: boolean) {
    if (value) {
      this.nav_layout = 'navbar-layoutOpen'
    } else {
      this.nav_layout = 'navbar-layout'
    }
  }
  sidebarMobile() {
    this.open = !this.open
    this.$emit('sidebarMobile', this.open)
  }
}
